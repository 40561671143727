.textArea {
  height: fit-content;
  max-width: 350px;
  color: white;
  padding: 10px;
}

.head1 {
  font-size: 30px;
  font-weight: 800;
}
.head2 {
  font-size: 30px;
  font-weight: 400;
}

.text {
  padding: 0 30px;
  font-size: 11px;
  text-align: center;
}

.errorMsg {
  height: 15px;
  font-size: 13px;
  color: rgb(233, 96, 5);
  text-align: center;
}