.tableContainer {
  border-radius: 10px !important;
  background-color: #EDEDF2 !important;
}
.columnHead {
  color: #8b8b8b !important;
}
.btn {
  border: 1px solid rgba(0, 0, 1, 0.1) !important;
}
.image {
  min-height: 50px;
  max-height: 50px;
  background-size: contain !important;
  margin: 5px;
  justify-content: center;
}
