.textArea {
    height: fit-content;
    max-width: 350px;
    color: white;
    padding: 10px;
  }
  
  .head1 {
    font-size: 30px;
    font-weight: 800;
  }
 

