.contentWrapper {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: white;
  padding: 1.5rem;
  color: #222222;
  overflow-y: auto;
  overflow-x: hidden;
}

.contentWrapper::-webkit-scrollbar {
  width: 0;
}
.contentWrapper::-webkit-scrollbar-thumb {
  height: 0;
}

.dataWrapper {
  height: calc(100% - 44px);
}

.subtitle {
  color: #8b8b8b;
  margin-bottom: 10px;
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px dashed #d1d1d1;
}
.itemContainer h5 {
  color: #8b8b8b;
  margin-top: 3px;
}

.container {
  padding: 1rem 1.5rem;
  background-color: #f5f5fb;
  border-radius: 13px;
}

.container h6 {
  color: #8b8b8b;
  margin-bottom: 5px;
}

.checkbox {
  display: flex;
  align-items: center;
}
.checkbox h4 {
  margin: 5px 0 0 10px;
}

.logoContainer {
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px dashed #d1d1d1;
  background-color: #f5f5fb;
}

.dragging {
  background-color: #e3f2fd;
}

.logoContainer h6 {
  color: #8b8b8b;
  margin-top: 5px;
}

.logoContainer span {
  color: #0160c9;
}

.logo {
  max-height: 100%;
  max-width: 20rem;
}

.uploader {
  opacity: 0;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.subHeader {
  color: #8b8b8b;
}
