.ContentsWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #f5f5fb;
  overflow-x: hidden;
  overflow-y: hidden;
}

.container {
  padding: 25px;
}

.navbar {
  height: 100%;
  width: 200px;
}

.contentArea {
  height: 100%;
  width: calc(100% - 216px) !important;
}
