.textArea {
  height: 100%;
  width: 100%;
  color: white;
  padding: 3rem 6rem;
}
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.head1 {
  font-size: 40px;
  font-weight: 800;
}
.head2 {
  font-size: 40px;
  font-weight: 400;
  padding-left: 10px;
}
.text {
  font-size: 12px;
  text-align: start;
  line-height: 15px;
}
.btnConteiner {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pwdButton {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.05);
  padding: 3px;
}
.btn {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
}
.btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.pwdActive {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);;
}


.support {
  font-size: 13px;
  position: absolute;
  left: 0;
  bottom: 0;
  height: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;;
}
.support:hover {
  color: rgba(255, 255, 255, 0.8);
}
.icon {
  font-size: 25px;
  padding-right: 5px;
  padding-bottom: 6px;
}
.settings {
  position: absolute;
  right: 0;
  top: 0; 
  height: fit-content;
  display: flex;
}
.subText {
  display: flex;
  padding-left: 15px;
  cursor: pointer;
  font-size: 13px;
}
.subText:hover {
  color: rgba(255, 255, 255, 0.8);
}
.subText span {
  padding-top: 5px;
}
