.contentWrapper {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: white;
  padding: 1.5rem 1rem;
  color: #222222;
}

.btnStyle {
  padding: 6px 12px !important;
  margin-left: 10px !important;
  text-transform: none !important;
}

.blueBtn {
  background-color: #0160c9 !important;
  color: white !important;
  text-transform: none !important;
}

.machineContainer {
  height: calc(100% - 56px);
  overflow-y: auto;
  overflow-x: hidden;
}

.machineItem {
  height: fit-content;
  display: flex;
  padding: 15px 25px;
  border-radius: 10px;
  background-color: #f5f5fb;
  justify-content: space-between;
}

.online {
  color: #14a600;
  padding-top: 2px;
}

.offline {
  color: #ff0404;
  padding-top: 2px;
}

.machine {
  display: flex;
  align-items: center;
  justify-content: center;
}

.machine h5 {
  margin-right: 10px;
}

.edit {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0160c9;
  margin-left: 5px;
  border-radius: 8px;
  cursor: pointer;
}

.delete {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0404;
  margin-left: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.icon {
  font-size: 15px !important;
  color: white !important;
}

/* .contentArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentArea > * {
  margin-bottom: 10px; 
} */