.container {
  height: 100vh;
  width: 100vw;
  background: url("../../../../public/images/Login_page_background.jpg") center
    top;
  background-size: cover;
  background-attachment: fixed;
}

.wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.67);
  display: flex;
  justify-content: center;
  align-items: center;
}
