/* container section  */

.popUp {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 50px 0px;
  z-index: 999;
}

.contentWrapper {
  min-width: 350px;
  width: 450px !important;
  max-width: 800px;
  background-color: white;
  border-top: 1px solid green;
  border-radius: 30px;
  padding: 1.5rem;
  color: #222222;
  animation: fadeInAndTranslate 0.3s ease-in-out forwards;
}

.billContentWrapper {
  min-width: 350px;
  max-width: 700px;
  height: fit-content;
  max-height: 100%;
  background-color: white;
  border-top: 1px solid green;
  border-radius: 30px;
  padding: 1.5rem;
  color: #222222;
  animation: fadeInAndTranslate 0.3s ease-in-out forwards;
}

.tableContainer {
  height: fit-content;
  max-height: 300px ;
  overflow-y: auto;
}

.tableContainer::-webkit-scrollbar {
  width: 0;
}
.tableContainer::-webkit-scrollbar-thumb {
  height: 0;
}

@keyframes fadeInAndTranslate {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* header section */

.headSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  border-bottom: 1px solid rgb(139, 139, 139, 0.2);
}
.headInfoSec {
  border-bottom: 1px solid rgb(139, 139, 139, 0.2);
  margin-bottom: 1rem;
}

/* content section */

.inputSec {
  height: fit-content;
  max-height: 440px;
  overflow-y: auto;
}

.contentArea {
  height: fit-content;
  max-height: 600px;
}

/* footer section */

.footSec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
  border-top: 1px solid rgba(0, 0, 1, 0.1);
}

.footerBtn {
  padding: 7px 10px !important;
  margin-left: 10px !important;
}

.amountBlue {
  display: flex;
  color: #0160c9 !important;
  margin-left: 10px;
}
.amountGray {
  display: flex;
  color: #222222 !important;
  margin-left: 10px;
}

.rupieeIcon {
  font-size: 13px !important;
}
.priceTxt {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  color: #bebebe;
  font-size: 35px !important;
}
.icon:hover {
  color: #b2b2b2;
}

.btn {
  height: 60px;
  width: 100%;
  border: 1px solid #b3cdee !important;
  border-radius: 18px !important;
  background-color: #f5f5fb !important;
  color: #222222 !important;
  box-shadow: none !important;
}
.btn:hover {
  background-color: #eeeefc !important;
}

.selectBtn {
  background-color: #0160c9 !important;
  color: white !important;
}
.selectBtn:hover {
  background-color: rgb(0, 99, 211) !important;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-right: 15px !important;
}

.checkbox h5 {
  color: #8b8b8b;
  margin-top: 5px;
}
.subTitle {
  color: #8b8b8b;
  margin-bottom: 5px;
}
.heighLite {
  color: #0160c9;
}

.buttonGray {
  background-color: #ABABAB !important;
  margin-right: 8px !important;
}

@media screen and (max-width: 700px) {
  .billContentWrapper {
    margin: 5px;
  }
}