.container {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: white;
  padding: 1rem 1.5rem;
  color: #222222;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px !important;
}
.icon {
  font-size: 30px !important;
  cursor: pointer;
}
.title {
  margin: 5px 0 0 10px;
}
.subTitle {
  color: #8b8b8b;
  padding-right: 5px;
  font-weight: 400 !important;
}
.userInfo {
  width: fit-content;
  display: flex;
  flex-direction: column !important;
}
.contentWrapper {
  height: calc(100% - 52px);
}
.wrapper {
  height: 100%;
  width: 100%;
}
.cartWrapper {
  height: 100%;
  width: 280px;
  background-color: #f5f5fb;
  border-radius: 30px;
  position: relative;
  color: #222222;
}
.cartItemList {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 130px);
  padding: 18px 18px 0;
  overflow-y: auto;
}
.cartItemList::-webkit-scrollbar {
  width: 0;
}
.cartItemList::-webkit-scrollbar-thumb {
  height: 0;
}
.totalWrapper {
  box-shadow: 0px -2px 6px -4px rgba(0, 0, 0, 0.22);
  padding: 18px;
}
.priceContainer {
  border-bottom: 1px dashed #d1d1d1;
  padding-bottom: 5px;
  margin-bottom: 12px;
}
.rupieeIcon {
  font-size: 12px !important;
}
.dataWrapper {
  width: calc(100% - 296px);
  margin-left: 1rem !important;
  height: 100%;
}
.dataContainer {
  background-color: #f5f5fb;
  border-radius: 30px;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
}
.dataContainer h5,
.paymentWrapper h5 {
  color: #8b8b8b;
  padding-right: 5px;
  font-weight: 400 !important;
  margin-bottom: 5px;
}
.dataContainer h4,
.paymentWrapper h4 {
  font-weight: 600 !important;
}
.paymentWrapper {
  background-color: #f5f5fb;
  border-radius: 30px;
  padding: 1rem 1.5rem;
  height: calc(100% - 180px);
}
.paymentType {
  height: fit-content;
  display: flex;
  flex-direction: column !important;
  padding: 5px 2rem;
  border-radius: 17px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.paymentType:hover {
  background-color: #0160c9;
}
.paymentType:hover h5 {
  color: rgba(255, 255, 255, 0.8) !important;
}
.paymentType:hover h4 {
  color: white !important;
}
.selected {
  background-color: #0160c9;
}
.selected h5 {
  color: rgba(255, 255, 255, 0.8) !important;
}
.selected h4 {
  color: white !important;
}

.button {
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid #b3cdee;
  border-radius: 18px;
  background-color: #f5f5fb;
  color: #222222;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.button:hover {
  background-color: #eeeefc;
}
.btnStyle {
  background-color: #c0c0c3 !important;
  color: white !important;
  height: 100% !important;
  border-radius: 18px !important;
}
.closeBtn {
  background-color: #c0c0c3 !important;
  text-transform: uppercase !important;
  padding: 10px !important;
  color: white !important;
}
