.contentWrapper {
  height: 70px;
  width: 100%;
  border-radius: 23px;
  background-color: white;
}
.container {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 15px;
  border-radius: 12px;
  align-items: center;
}
.container::-webkit-scrollbar {
  display: none;
}
.wrapper {
  width: 100%;
  height: 70px;
  border-radius: 23px;
  background-color: white;
  padding: 10px 0px;
}
.wrapper a {
  text-decoration: none;
  color: inherit;
}
.btnStyle {
  padding: 5px 15px !important;
}
.btnStyle span {
  font-size: 16px;
  padding-top: 5px;
}
.icon {
  padding-right: 8px;
  padding-bottom: 5px;
  font-size: 28px !important;
}
