.container {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: white;
  padding: 1rem 1.5rem;
  color: #222222;
}

.titleWrapper {
  margin-left: 10px;
}

.icon {
  font-size: 30px !important;
  cursor: pointer;
}

.smallIcon {
  font-size: 16px !important;
  margin: 0 5px 4px;
}

.contentWrapper {
  height: calc(100% - 96px);
  overflow-x: hidden;
  overflow-y: auto;
}

.item {
  height: 250px;
  background-color: #f5f5fb;
  padding: 1rem;
  border-radius: 30px;
}

.title {
  color: #8b8b8b;
  margin-right: 10px;
}

.itemList {
  height: 90px;
  overflow-y: auto;
  overflow-x: hidden;
}
.itemList::-webkit-scrollbar {
  width: 0;
}
.itemList::-webkit-scrollbar-thumb {
  height: 0;
}