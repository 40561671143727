.contentWrapper {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: white;
  padding: 1.5rem;
  color: #222222;
}

.dataWrapper {
  height: calc(100% - 44px);
  overflow-y: auto;
}

.container {
  border-radius: 30px;
  padding: 2rem 1.5rem;
  background-color: #f5f5fb;
}

.container h5 {
  color: #8b8b8b;
  margin: 20px 0 10px;
}
