.textArea {
  height: fit-content;
  max-width: 350px;
  color: rgba(256, 256, 256, 0.8);
  padding: 10px;
}

.head1 {
  font-size: 25px;
  font-weight: 800;
}
.head2 {
  font-size: 25px;
  font-weight: 400;
}

.text {
  padding: 0 30px;
  font-size: 11px;
  text-align: center;
}

.selectWrapper {
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: rgba(25, 118, 210, 0.7);
}

.selectContainer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(256, 256, 256, 0.4);
  cursor: pointer;
}

.selectContainer:hover {
  transform: scale(1.05)
}

.errorMsg {
  height: 15px;
  font-size: 13px;
  color: rgb(233, 96, 5);
  text-align: center;
}
