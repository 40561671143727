.itemContainer {
  height: 160px;
  width: 170px;
}

.itemArea {
  height: 100%;
  width: 100%;
}

.item {
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 16px;
  box-shadow: rgba(0,0,0,0.1) 0px 3px 5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.item:hover {
  transform: scale(1.02);
}

.item h5 {
  font-size: 15px;
  text-transform: capitalize;
  color: #222222;
  margin-bottom: 3px;
}

.itemImage {
  border-radius: 16px;
  width: 100%;
  height: 90px;
  margin-bottom: 5px;
}

.item span {
  font-size: 10px;
}

