.container {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: white;
  padding: 1rem 1.5rem;
  color: #222222;
}

.titleWrapper {
  margin-left: 10px;
}

.iconLarge {
  font-size: 30px !important;
  cursor: pointer;
}

.smallIcon {
  font-size: 16px !important;
  margin: 0 5px 4px;
}

.contentWrapper {
  height: calc(100% - 54px);
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 30px;
}
.contentWrapper::-webkit-scrollbar-thumb {
  height: 0;
}
.contentWrapper::-webkit-scrollbar {
  width: 0;
}
.itemContainer {
  background-color: #f5f5fb;
  border-radius: 30px;
  padding: 1rem 1.5rem;
}
.item {
  height: 20px;
  background-color: aquamarine;
}
.icon {
  font-size: 20px !important;
  color: #8b8b8b;
}
.dateItem {
  display: flex;
  align-items: center;
}
.dateItem h4 {
  margin: 6px 5px 0;
  color: #8b8b8b;
  width: 130px;
  font-weight: 500;
}
.summaryCard {
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  border-radius: 13px;
}
.iconWrapper {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
}
.largeIcon {
  color: white;
  font-size: 24px !important;
  color: #8b8b8b;
}
.summaryText {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between !important;
  margin-top: 5px;
}
.summaryText h4 {
  color: #8b8b8b;
  margin-bottom: 3px;
}
