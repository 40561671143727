.CartWrapper {
  background-color: white;
  border-radius: 30px;
  position: relative;
  height: 100%;
  color: #222222;
}

.saleInfo {
  height: 60px;
  border-bottom: 1px dashed #d1d1d1;
  padding: 18px;
}

.cartItemList {
  height: calc(100% - 260px);
  padding: 5px 18px 0 18px;
  overflow-y: auto;
}
.cartItemList::-webkit-scrollbar {
  width: 0;
}
.cartItemList::-webkit-scrollbar-thumb {
  height: 0;
}
.saleInfo h6 {
  margin-bottom: 5px;
  color: #8b8b8b;
}

.cartItemImg {
  height: 50px;
  width: 50px;
  border-radius: 8px;
}
.cartItem {
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
  height: fit-content;
}
.cartItemInfo span {
  margin-top: 3px;
  font-size: 12px;
  color: #8b8b8b;
}
.cartItemInfo h5 {
  margin-top: 3px;
}
.cartItemInfo div {
  display: flex;
  align-items: center;
}
.icon {
  font-size: 12px !important;
}

.addCartWrapper {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.addCartWrapper button {
  padding: 4px !important;
}

.addCartWrapper h5 {
  margin: 3px 7px 0 7px;
}

.smallIcon {
  font-size: 13px !important;
  color: #8b8b8b;
}
.cartItemEdit {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
  justify-content: space-between;
}

.cartItemEdit button {
  padding: 4px 12px !important;
}

.totalWrapper {
  height: fit-content;
  box-shadow: 0px -5px 5px -5px #f1f1f1;
  padding: 18px 18px 18px 18px;
}

.priceContainer {
  border-bottom: 1px dashed #d1d1d1;
  padding-bottom: 5px;
  margin-bottom: 12px;
}

.rupieeIcon {
  font-size: 12px !important;
}

.billBtn {
  padding: 5px !important;
  margin-top: 12px !important;
  min-width: none !important;
}

.description {
  font-size: 12px !important;
  color: #8b8b8b;
  font-style: italic;
}
