.CategoryWrapper {
  background-color: white;
  border-radius: 30px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.logoWrapper {
  height: 100px;
  border-bottom: 1px dashed #d1d1d1;
}

.logoContainer {
  height: 80px;
  padding: 0 25px;
}

.logoContainer img {
  width: 100%;
  height: 20px;
}

.subText {
  height: 20px;
}

.subText span {
  font-size: 14px;
  color: #8b8b8b;
  font-weight: 500;
}

.navList {
  height: calc(100% - 181px);
  overflow-y: auto;
  overflow-x: hidden;
}
.navList::-webkit-scrollbar {
  width: 0;
}
.navList::-webkit-scrollbar-thumb {
  height: 0;
}
.navItems {
  padding: 9px 0;
  font-size: 15px;
  color: #8b8b8b;
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 0.1s ease-in;
}

.navItems:hover {
  color: #0160c9;
}

.selectedCategory {
  color: #0160c9 !important;
}

.logoutSeccion {
  height: 45px;
  width: calc(100% - 36px);
}

.btnStyle {
  padding: 10px !important;
}
