.contentWrapper {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 14px 14px 0;
  border-radius: 23px;
  background-color: white;
}

.btnStyle {
  padding: 8px 12px !important;
}

.icon {
  padding-right: 8px;
  font-size: 28px !important;
}