.CategoryWrapper {
  background-color: white;
  border-radius: 30px;
  padding: 18px;
  position: relative;
  height: 100%;
}

.logoWrapper {
  height: 100px;
  border-bottom: 1px dashed #d1d1d1;
}

.logoContainer {
  height: 80px;
  padding: 0 25px;
}

.logoContainer img {
  width: 100%;
  height: 20px;
}

.subText {
  height: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}

.icon {
  font-size: 20px !important;
  color: #8b8b8b;
  margin-right: 8px;
}

.subText span {
  font-size: 14px;
  color: #8b8b8b;
  font-weight: 500;
}

.navList {
  height: calc(100% - 100px);
  padding-top: 20px;
  padding-bottom: 60px;
}
.logoutSeccion {
  height: 45px;
  width: calc(100% - 36px);
  position: absolute;
  bottom: 18px;
}

.btnStyle {
  padding: 10px !important;
}

.navLinkWrapper {
  display: flex;
  padding: 10px 0;
  width: 100%;
  color: #8b8b8b;
  text-decoration: none;
  justify-content: space-between;
  transition: all 0.3s ease-in-out !important;
}
.navLinkWrapper:hover {
  color: #0160c9;
}
.activeNavLink {
  color: #0160c9;
}
.activeNavLinkIcon {
  color: #0160c9 !important;
}
.icon {
  font-size: 20px !important;
  margin-top: 1px;
  color: #8b8b8b;
  transition: all 0.3s ease-in-out !important;
}
.navLinkWrapper:hover .icon {
  color: #0160c9;
}
.navLinkText {
  padding-left: 8px;
  font-size: 15px;
  padding-top: 6px;
}

.navSubMenuItem {
  margin-left: 28px;
}

.navbarContainer {
  width: fit-content;
  height: fit-content;
  display: flex;
}

.subItemIcon {
  font-size: 24px !important;
  transition: all 0.3s ease-in-out !important;
}

.subItemIconOpen {
  transform: rotate(180deg);
}
