.contentWrapper {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: white;
  padding: 1.5rem;
  color: #222222;
}

.dataWrapper {
  height: calc(100% - 44px);
  overflow-y: auto;
}

.container {
  padding: 2rem 1.5rem;
  background-color: #f5f5fb;
  border-radius: 30px;
  height: fit-content;
}
.itemContainer {
  padding: 2rem 1.5rem;
  background-color: #f5f5fb;
  border-radius: 30px;
  height: 400px;
}
.itemContainer h5 {
  margin: 8px 0;
  color: #8b8b8b;
}
.itemWrapper {
  height: 235px;
  overflow-y: auto;
}
.itemWrapper::-webkit-scrollbar {
  width: 0;
}
.itemWrapper::-webkit-scrollbar-thumb {
  height: 0;
}

.copyField {
  width: calc(100% - 100px);
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 12px 0px 0px 12px;
  background-color: white;
  color: red;
}
.copyBtn {
  height: 40px;
  width: 100px;
  border-radius: 0px 12px 12px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  color: #8b8b8b;
  cursor: pointer;
}
.copyBtn:hover {
  background-color: #cecece;
}

.item {
  padding: 5px 15px;
  background-color: white;
  border-radius: 10px;
}
.item h5 {
  color: #8b8b8b;
  text-transform: capitalize;
}

.icon {
  font-size: 18px !important;
  margin-right: 5px;
}
