.contentWrapper {
  width: 100%;
  height: calc(100% - 86px);
  border-radius: 30px;
  background-color: white;
  padding: 1.5rem 1rem !important;
  color: #222222;
}

.btnStyle {
  padding: 6px 12px !important;
  margin-left: 10px !important;
}

.itemContainer {
  width: 100%;
  height: calc(100% - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}

.rupeeIcon {
  height: 10px;
  width: 10px;
  margin-right: 3px;
}

.title {
  display: flex;
  flex-direction: column;
}

.headSec {
  display: flex;
}

.infoDiv {
  display: flex;
  margin-left: 15px;
  margin-top: 2px;
}

.headSec h5 {
  margin-top: 2px;
}

.icon {
  font-size: 16px !important;
  margin: 0 5px !important;
  color: #8b8b8b !important;
}
