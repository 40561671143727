.container {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: white;
  padding: 1rem 1.5rem;
  color: #222222;
}
.titleWrapper {
  margin-left: 10px;
}
.largeIcon {
  font-size: 30px !important;
  cursor: pointer;
  color: #222222;
}
.smallIcon {
  font-size: 16px !important;
  margin: 0 5px 4px;
}
.contentWrapper {
  padding: 10px 15px;
  background-color: #f5f5fb;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dataWrapper {
  height: calc(100% - 64px);
  overflow-y: auto;
}
.filterWrapper {
  padding: 10px;
  background-color: #f5f5fb;
  border-radius: 13px;
}
.tableContainer {
  height: calc(100% - 140px);
  overflow-y: auto;
}
.actionBtnIcon {
  margin-right: 6px;
  font-size: 20px !important;
}
.icon {
  font-size: 20px !important;
  color: #8b8b8b;
}
.dateItem {
  display: flex;
  align-items: center;
}
.dateItem h4 {
  margin: 6px 5px 0;
  color: #8b8b8b;
  width: 130px;
  font-weight: 500;
}
.label {
  font-size: 14px;
  color: #8b8b8b;
}
.field {
  font-size: 14px;
}
