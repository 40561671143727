
.navbar {
  height: 100%;
  width: 200px;
}

.contentArea {
  height: 100%;
  width: calc(100% - 512px) !important;
}

.contentArea h2 {
  color: #222222;
}

.warrningText {
  color: #ff0404;
  font-size: 12px;
  font-weight: 800;
}

.infoText {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 800;
}

.itemListWrapper {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 170px);
  overflow-y: auto;
}

.cartWrapper {
  height: 100%;
  width: 280px;
}

.rupeeIcon {
  height: 10px;
  width: 10px;
  margin-right: 3px;
}