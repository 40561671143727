.textArea {
  height: fit-content;
  max-width: 550px;
  padding: 10px;
  color: #ffffff;
}

.head1 {
  font-size: 25px;
  font-weight: 800;
}

.head2 {
  font-size: 25px;
  font-weight: 400;
}

.title {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}

.text {
  padding: 0 30px;
  font-size: 11px;
  text-align: center;
}

.errorMsg {
  height: 15px;
  font-size: 13px;
  color: rgb(233, 96, 5);
  text-align: center;
}
